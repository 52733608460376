<template>
  <div>
  <!-- <b-button variant="primary" size="lg">Upgrade Plan</b-button> &nbsp;
  <b-button variant="outline-danger" size="lg">Cancel Subscription</b-button>  -->
  
  <div class="col-12">
    <b-card>
      <p v-if="isLoading">
        <loading></loading>
      </p>  
      <b-row v-if="!isLoading">
        <b-col
          sm="12"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            Quota
          </h1>
          <strong>{{ dataNdrivePercentage.quota }} GB</strong>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <!-- chart -->
        <b-col
          sm="12"
          lg="4"
          class="d-flex justify-content-center"
        >
          <!-- apex chart -->
          <vue-apex-charts
            v-if="radialBarExist"
            type="radialBar"
            height="270"
            :options="chartDataUsed.supportTrackerRadialBar.chartOptions"
            :series="[dataNdrivePercentage.used_p]"
          />
        </b-col>
        <b-col
          sm="12"
          lg="4"
          class="d-flex justify-content-center"
        ></b-col>
        <b-col
          sm="12"
          lg="4"
          class="d-flex justify-content-center"
        >
          <!-- apex chart -->
          <vue-apex-charts
            v-if="radialBarExist"
            type="radialBar"
            height="270"
            :options="chartDataFree.supportTrackerRadialBar.chartOptions"
            :series="[dataNdrivePercentage.free_p]"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</div>
  
</template>

<script>
import VueApexCharts from 'vue-apexcharts' 
import {
  BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton, BTable, BRow, BCol
} from 'bootstrap-vue'
import axios from "@axios"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import Loading from '@core/components/loading/Loading.vue'
import { $themeColors } from '@themeConfig'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
    BTable,
    Loading,
    VueApexCharts,
    BRow,
    BCol
  },
  props: {
    customerCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dataNdrive: [],
      dataNdrivePercentage: [],
      fields: [
        { key: 'quota', label: 'Quota' },
        { key: 'used', label: 'Used' },
        { key: 'free', label: 'Free' }
      ],
      isLoading: false,
      radialBarExist: false,
      chartDataFree: {},
      chartDataUsed: {} 
    }
  },
  methods: {
    init() {
      this.getPlan();
    },
    getPlan() {
        this.isLoading = true;
        axios.post("third_party/ndrive_user_information" ,{customer_code:this.customerCode}).then((response) => {
          if(response.data.status == 2000) {
            this.dataNdrive = response.data.data;
            this.dataNdrivePercentage = this.dataNdrive[0];
            this.chartDataUsed = {
              supportTrackerRadialBar: {
                series: [80],
                chartOptions: {
                  chart: {
                    type: 'radialBar',
                  },
                  plotOptions: {
                    radialBar: {
                      size: 150,
                      offsetY: 20,
                      startAngle: -150,
                      endAngle: 150,
                      hollow: {
                        size: '65%',
                      },
                      track: {
                        background: '#fff',
                        strokeWidth: '100%',
                      },
                      dataLabels: {
                        name: {
                          offsetY: -5,
                          color: '#5e5873',
                          fontSize: '1rem',
                        },
                        value: {
                          offsetY: 15,
                          color: '#5e5873',
                          fontSize: '1.714rem',
                        },
                      },
                    },
                  },
                  colors: [$themeColors.danger],
                  fill: {
                    type: 'gradient',
                    gradient: {
                      shade: 'dark',
                      type: 'horizontal',
                      shadeIntensity: 0.5,
                      gradientToColors: [$themeColors.primary],
                      inverseColors: true,
                      opacityFrom: 1,
                      opacityTo: 1,
                      stops: [0, 100],
                    },
                  },
                  stroke: {
                    dashArray: 8,
                  },
                  labels: ['Used Quota ('+this.dataNdrivePercentage.used + ' GB'+')'],
                },
              },
              goalOverviewRadialBar: {
                chart: {
                  height: 245,
                  type: 'radialBar',
                  sparkline: {
                    enabled: true,
                  },
                  dropShadow: {
                    enabled: true,
                    blur: 3,
                    left: 1,
                    top: 1,
                    opacity: 0.1,
                  },
                },
                colors: [$goalStrokeColor2],
                plotOptions: {
                  radialBar: {
                    offsetY: -10,
                    startAngle: -150,
                    endAngle: 150,
                    hollow: {
                      size: '77%',
                    },
                    track: {
                      background: $strokeColor,
                      strokeWidth: '50%',
                    },
                    dataLabels: {
                      name: {
                        show: false,
                      },
                      value: {
                        color: $textHeadingColor,
                        fontSize: '2.86rem',
                        fontWeight: '600',
                      },
                    },
                  },
                },
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'dark',
                    type: 'horizontal',
                    shadeIntensity: 0.5,
                    gradientToColors: [$themeColors.success],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100],
                  },
                },
                stroke: {
                  lineCap: 'round',
                },
                grid: {
                  padding: {
                    bottom: 30,
                  },
                },
              },
            };
            this.chartDataFree = {
              supportTrackerRadialBar: {
                series: [80],
                chartOptions: {
                  chart: {
                    type: 'radialBar',
                  },
                  plotOptions: {
                    radialBar: {
                      size: 150,
                      offsetY: 20,
                      startAngle: -150,
                      endAngle: 150,
                      hollow: {
                        size: '65%',
                      },
                      track: {
                        background: '#fff',
                        strokeWidth: '100%',
                      },
                      dataLabels: {
                        name: {
                          offsetY: -5,
                          color: '#5e5873',
                          fontSize: '1rem',
                        },
                        value: {
                          offsetY: 15,
                          color: '#5e5873',
                          fontSize: '1.714rem',
                        },
                      },
                    },
                  },
                  colors: [$themeColors.danger],
                  fill: {
                    type: 'gradient',
                    gradient: {
                      shade: 'dark',
                      type: 'horizontal',
                      shadeIntensity: 0.5,
                      gradientToColors: [$themeColors.primary],
                      inverseColors: true,
                      opacityFrom: 1,
                      opacityTo: 1,
                      stops: [0, 100],
                    },
                  },
                  stroke: {
                    dashArray: 8,
                  },
                  labels: ['Free Quota ('+this.dataNdrivePercentage.free + ' GB'+')'],
                },
              },
              goalOverviewRadialBar: {
                chart: {
                  height: 245,
                  type: 'radialBar',
                  sparkline: {
                    enabled: true,
                  },
                  dropShadow: {
                    enabled: true,
                    blur: 3,
                    left: 1,
                    top: 1,
                    opacity: 0.1,
                  },
                },
                colors: [$goalStrokeColor2],
                plotOptions: {
                  radialBar: {
                    offsetY: -10,
                    startAngle: -150,
                    endAngle: 150,
                    hollow: {
                      size: '77%',
                    },
                    track: {
                      background: $strokeColor,
                      strokeWidth: '50%',
                    },
                    dataLabels: {
                      name: {
                        show: false,
                      },
                      value: {
                        color: $textHeadingColor,
                        fontSize: '2.86rem',
                        fontWeight: '600',
                      },
                    },
                  },
                },
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'dark',
                    type: 'horizontal',
                    shadeIntensity: 0.5,
                    gradientToColors: [$themeColors.success],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100],
                  },
                },
                stroke: {
                  lineCap: 'round',
                },
                grid: {
                  padding: {
                    bottom: 30,
                  },
                },
              },
            };
            
            this.radialBarExist = true;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Data NDrive",
                icon: "EditIcon",
                variant: "success",
                text: response.data.message,
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Data NDrive",
                icon: "EditIcon",
                variant: "danger",
                text: response.data.message,
              },
            });
          }
          this.$emit('reloadParent');
        }).catch((error) => {}).finally(() => { this.isLoading = false; });
    },
  },
  mounted() {
    this.init();
  }
}
</script>
