<template>
  <div>
          <b-tabs
            pills
            class="profile-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <!-- Activation tab -->
            <b-tab 
              :active="showActivation" 
              @click="getContent(1)"
              :disabled="isDisable"
            >
              <!-- title -->
              <template #title>
                <feather-icon icon="UserIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">Activation</span>
              </template>
              <br>
              <tab-activation v-if="showActivation" :customerCode="customerCode" @reloadParent="init"></tab-activation>
            </b-tab>
            <!--/ Activation tab -->
            <!-- Security tab -->
            <!-- <b-tab 
              :active="showSecurity"
              @click="getContent(2)"
              :disabled="showActivation"
            > -->
              <!-- title -->
              <!-- <template #title>
                <feather-icon icon="LockIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">Security</span>
              </template>
              <br>
              <tab-security v-if="showSecurity" :customerCode="customerCode"></tab-security>
            </b-tab> -->
            <!--/ Security tab -->
            <!-- Plan tab -->
            <b-tab 
              :active="showPlan"
              @click="getContent(3)"
              :disabled="showActivation"
            >
              <!-- title -->
              <template #title>
                <feather-icon icon="InfoIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">Information</span>
              </template>
              <br>
              <tab-plan v-if="showPlan" :customerCode="customerCode" @reloadParent="init"></tab-plan>
            </b-tab>
            <!--/ Plan tab -->
              
          </b-tabs>
</div>
</template>

<script>
import {
  BCard, BTabs, BButton, BTab
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from "@axios"
// tab
import TabActivation from './tabs/Activation.vue'
import TabSecurity from './tabs/Security.vue'
import TabPlan from './tabs/Plan.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BButton,
    TabActivation,
    TabSecurity,
    TabPlan
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      customerCode: localStorage.getItem("customer_code"),
      showActivation:false, 
      showSecurity:false,
      showPlan:false,
      isDisable:false,
    };
  },
  methods: {
    init() {
      this.checkActive();
    },
    checkActive() {
      axios.post("third_party/status_activation" ,{customer_code:this.customerCode, service_type:'NDrive'}).then((response) => {
          this.isDisable = response.data.isDisable;
          if(this.isDisable) {
            this.showActivation = false;
            this.showSecurity = false;
            this.showPlan = true;
          } else {
            this.showActivation = true;
            this.showSecurity = false;
            this.showPlan = false;
          }
      }).catch((error) => {}).finally(() => {});
    },
    getContent(e) {
      switch(e) {
        case 1:
            this.showActivation = true;
            this.showSecurity = false;
            this.showPlan = false;
            break;
        case 2:
            this.showActivation = false;
            this.showSecurity = true;
            this.showPlan = false;
            break;
        case 3:
            this.showActivation = false;
            this.showSecurity = false;
            this.showPlan = true;
            break;
      }
    }
  },
  mounted() {
    this.init();
  }
}
</script>
