<template>
  <b-card>
        <b-button variant="primary" size="lg" @click="getActive()">Reset Password</b-button> 
  </b-card>
</template>

<script>
import {
  BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton,
} from 'bootstrap-vue'
import axios from "@axios"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  props: {
    customerCode: {
      type: String,
      default: null,
    },
  },
  methods: {
    getActive() {
      window.location.href = "https://ndrive.netciti.co.id";
      // if (confirm("Are you sure want to reset NDrive password ?")) {
      //   axios.post("third_party/reset_password" ,{customer_code:this.customerCode, service_type:'NDrive'}).then((response) => {
      //     if(response.data.message == 'Reset Password Successfull') {
      //       this.$toast({
      //         component: ToastificationContent,
      //         props: {
      //           title: "Reset Password NDrive",
      //           icon: "EditIcon",
      //           variant: "success",
      //           text: response.data.message,
      //         },
      //       });
      //     } else {
      //       this.$toast({
      //         component: ToastificationContent,
      //         props: {
      //           title: "Reset Password NDrive",
      //           icon: "EditIcon",
      //           variant: "danger",
      //           text: response.data.message,
      //         },
      //       });
      //     }
      //     this.$emit('reloadParent');
      //   }).catch((error) => {}).finally(() => { });
      // }
    },
  },
}
</script>
