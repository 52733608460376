<template>
  <b-card >
    <p style="text-align: center;">    
      <b-button v-if="!isLoading" variant="primary" size="lg" @click="getActive()">Activate Your NDrive Account</b-button> 
    </p>
    <p v-if="isLoading">
      <loading></loading>
    </p>  
  </b-card>
</template>

<script>
import {
  BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton,
} from 'bootstrap-vue'
import axios from "@axios"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import Loading from '@core/components/loading/Loading.vue'

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
    Loading,
  },
  props: {
    customerCode: {
      type: String,
      default: null,
    },
    showActivation: {
      type: Boolean,
      default: false,
    },
    
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    getActive() {
      if (confirm("Are you sure want to activating NDrive ?")) {
        this.isLoading = true;
        axios.post("third_party/third_party_activation" ,{customer_code:this.customerCode, service_type:'NDrive'}).then((response) => {
          if(response.data.status == 2000) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Aktivation NDrive",
                icon: "EditIcon",
                variant: "success",
                text: response.data.message,
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Aktivation NDrive",
                icon: "EditIcon",
                variant: "danger",
                text: response.data.message,
              },
            });
          }
          this.$emit('reloadParent');
        }).catch((error) => {}).finally(() => { this.isLoading = false; });
      }
    },
  },
}
</script>
