<template>
    <ndrive-header />
</template>

<script>
import NdriveHeader from "./NdriveHeader.vue";
export default {
    components: {
        NdriveHeader
    }
}
</script>

<style>

</style>